@import 'src/styles/colors.scss';

$headerHeight: 100px;

.container {
    display: flex;
    justify-content: space-between;
    height: $headerHeight;

    .logo {
        height: $headerHeight;
    }

    .link {
        text-decoration: none;
        color: $blue;
        font-size: 20px;

        // .activeLink -> in base.scss

        &Container {
            display: flex;
            flex: 1;
            justify-content: space-evenly;
            align-items: center;
            height: $headerHeight;
        }
    }
}
