@import 'styles/colors';

.section {
    display: flex;
    padding: 0 4px;
    color: $very-dark-blue;

    &Container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .keyContainer {
        width: 50%;
    }

    &Title {
        background: $blue;
        padding: 4px;
        margin-bottom: 2px;
        color: white;
        font-size: 16px;
    }
}

.topBar {
    display: flex;
    justify-content: space-between;
    margin: 16px 0 32px;

    & > .selectContainer {
        width: 30%;
    }

    &Text {
        display: flex;
        align-items: flex-end;
        font-size: 16px;

        .placeholder40px {
            width: 40px;
        }
    }
}

.progressBarContainer {
    margin-bottom: 20px;
}

.button {
    &Group {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
    }
}

.linkContainer {
    margin-top: 20px;
}

.graphImage {
    width: 100%;
}
