@import 'styles/colors';

.paperBody {
    display: flex;
    padding: 12px;
    color: $very-dark-blue;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;

        .select {
            margin: 12px 0 32px;
        }
    }
}

.submitButton {
    width: 200px;

    &Container {
        display: flex;
        justify-content: center;
    }
}
