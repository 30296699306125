@import './colors.scss';

.link {
    text-decoration: none;
    color: $blue;
    font-weight: bolder;
    cursor: pointer;

    &:hover {
        color: $dark-blue;
    }
}

.activeLink {
    color: $orange !important;
    font-weight: bold;
}

.light-link {
    text-decoration: none;
    color: $light-blue;
    font-weight: bolder;
    cursor: pointer;

    &:hover {
        color: $blue;
    }
}

.backdrop {
    z-index: 5;
    color: $orange;

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.upwardSpinningArrow {
    display: inline-block;
    transform: rotate(-180deg);
    transition: transform 0.2s;
}

.downwardSpinningArrow {
    display: inline-block;
    transition: transform 0.2s;
}

.pictureUpload {
    & > div {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.23);
    }

    &__button {
        background-color: $very-light-orange !important;
        border: 1px solid $orange !important;
        color: $dark-orange !important;
        font-weight: 500 !important;
        letter-spacing: 1.1px !important;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        margin: 10px 0 0 !important;

        &:hover {
            background-color: $light-orange !important;
        }

        &--mod {
            position: absolute;
            bottom: 0.3rem;
            right: 0.3rem;
            margin: 0 !important;
            padding: 6px 12px !important;
            letter-spacing: 0 !important;
            font-size: 1rem !important;
            font-weight: bold !important;
            // background-color: #00c853 !important;
            background-color: $blue !important;
            color: white !important;
            border: none !important;

            &:hover {
                background-color: $dark-blue !important;
            }
        }

        &--hidden {
            display: none;
        }
    }

    & > div > div > div > div > div {
        position: absolute;
        top: -9px;
        right: -9px;
        color: #f44336 !important;
        // background: #ff4081; // the original
        background: #f44336 !important;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        font-size: 16px !important;
        font-weight: bold;
        line-height: 24px !important;
        width: 25px !important;
        height: 24px !important;
        transition: all 0.2s ease-in;

        &:hover {
            background: #c2352b !important;
        }

        &::after {
            content: '+';
            position: absolute;
            left: 7px;
            top: 2px;
            transform: rotate(45deg);
            color: white !important;
            font-size: 1.3rem;
            line-height: 1.3rem;
        }
    }
}

.uploadPicturesWrapper {
    & > div {
        margin-bottom: 1rem;

        & > .uploadPictureContainer {
            margin: 4%;
        }
    }
}

.fileContainer {
    box-shadow: none !important;
}

// HIDE ARROWS FROM NUMBER INPUT FIELDS
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
