$very-light-blue: #b2c2dc;
$light-blue: #8ea3c5;
$blue: #54709d; // Main Primary color */
$dark-blue: #365585;
$very-dark-blue: #213f6e;

$very-light-orange: #ffebc9;
$light-orange: #ffdda2;
$orange: #e8a838; // Main Complement color */
$dark-orange: #e2a23f;
$very-dark-orange: #a77825;

$white: #fff;
$black: #000;

$dark-blue-text: #445;

$blue-background: #eceff4;
$blue-table-background: #f8f9fb;

//Dashboard colors
$intense-red: #ef1c0b;
$medium-red: #f56e62;
$faded-red: #f6b9b4;

$intense-green: #0de30d;
$medium-green: #8cf38c;
$faded-green: #c2f8c2;

$intense-orange: #ea8821;
$medium-orange: #e0a66a;
$faded-orange: #ecc9a5;
