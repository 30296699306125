@import 'styles/main';
@import 'styles/colors';

.App {
    padding: 40px 40px 80px;
    display: flex;
    justify-content: center;
}

.paper {
    width: 800px;
    padding: 20px;
    background: $blue-table-background;
}

.sectionTitle {
    background: $blue;
    padding: 4px;
    margin-bottom: 2px;
    color: white;
    font-size: 16px;
}

.table {
    &-container {
        max-width: 1280px;
        padding: 20px;
        background: $blue-table-background;
    }

    &-header > th {
        font-weight: 700;
    }

    &-sub-text {
        margin: 2px 0;
        font-size: 12px;

        &-container {
            margin-top: 20px;
        }
    }
}

.pointer {
    cursor: pointer;
}

.blue-text {
    color: $very-dark-blue;
}

.text {
    &red {
        color: #f44336;
    }

    &blue {
        color: #365585;
    }

    &green {
        color: forestgreen;
    }

    &orange {
        color: darkorange;
    }

    &cyan {
        color: #14a472ff;
    }

    &darkblue {
        color: $very-dark-blue;
    }
}

.verticalPlaceholder12px {
    height: 12px;
}
