@import 'styles/colors';

.footer {
    font-size: 12px;
    margin: 2px 0;

    &Container {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 48px;
        background: $blue-background;
        color: $very-dark-blue;
    }
}
