@import './colors.scss';

.table-container {
    width: 1000px;

    .balance-container {
        padding: 0 25px;
        align-items: center;
        margin: 0 auto;

        .balance-list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid $very-light-blue;
            color: $dark-blue;

            p {
                width: 25%;
                text-align: center;
            }
        }

        .warning {
            color: red;
            font-weight: bold;
        }

        .bold {
            font-weight: bold;
        }
    }
}
