@import 'styles/colors.scss';

.main {
    padding: 10vh 0;
    display: flex;
    justify-content: center;
}

.paper {
    width: 500px;
    align-items: center;
    padding: 1.5rem 3rem 2.5rem;
}

.avatar {
    margin: 0.5rem auto;
    background-color: $orange;
}

.formField {
    margin-bottom: 1rem;
}

.submitButton {
    margin-top: 1rem;
}
