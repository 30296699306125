@import './mixins.scss';
@import './colors.scss';

.dashboard-background-container {
    width: 100%;
    max-width: 1100px;
    background: $blue-table-background;
    display: flex;
    justify-content: center;

    .dashboard-container {
        width: 100%;
        max-width: 1000px;
        padding: 20px 0;

        .server-data-container {
            .indicator-light-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;

                .circle {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    cursor: pointer;
                    border: 2px solid black;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0;

                    &-scenario {
                        width: 50px;
                        height: 50px;
                    }

                    &red {
                        background-color: $intense-red;
                        @include colored-shadow($faded-red, $medium-red);
                    }

                    &cyan {
                        background-color: #14a472;
                        @include colored-shadow(#c9d5e7, #4af8bb);
                    }

                    &green {
                        background-color: $intense-green;
                        @include colored-shadow($faded-green, $medium-green);
                    }

                    &orange {
                        background-color: $intense-orange;
                        @include colored-shadow($faded-orange, $medium-orange);
                    }
                }

                .selected {
                    @include selected-shadow($blue, $dark-blue);
                }
            }
        }

        .details-container,
        .duration-container {
            padding: 0 25px;
            align-items: center;
            margin: 0 auto;

            .close-button {
                float: right;
                cursor: pointer;
            }

            .connected-servers {
                margin-right: 10px;
            }

            [data-title]:hover:after {
                opacity: 1;
                display: block;
            }

            [data-title]:after {
                content: attr(data-title);
                width: 100%;
                text-align: center;
                position: absolute;
                bottom: 1.2em;
                left: 0;
                padding: 4px 4px 4px 8px;
                background-color: $dark-blue;
                color: white;
                border: 1px solid white;
                border-radius: 5px;
                display: none;
            }

            [data-title] {
                position: relative;
            }

            .clickable {
                cursor: pointer;
                font-weight: bold;
            }

            .clickable:hover {
                text-decoration: underline;
            }

            .report-list {
                display: flex;
                justify-content: space-between;

                p {
                    width: 20%;
                    text-align: center;
                }
            }

            .bold {
                font-weight: bold;
            }

            .last-time-ok {
                font-weight: bold;
                color: green;
            }

            .last-time-not-ok {
                font-weight: bold;
                color: red;
            }
        }
    }
}
