@import 'styles/colors.scss';

// Switch
.MuiSwitch-colorPrimary.Mui-checked {
    color: $blue;

    & + .MuiSwitch-track {
        background-color: $light-blue;
    }
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: $orange;

    & + .MuiSwitch-track {
        background-color: $light-orange;
        border: 1px solid $orange;
    }
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiFormGroup-row {
    justify-content: space-around;
}

.MuiFormControlLabel-label {
    font-size: 14px;
}

.MuiSelect-select {
    color: $dark-blue;
    &:before,
    &:after {
        border-color: #e2a23f;
    }
}

.MuiSelect-icon {
    fill: $dark-blue;
}

.MuiInputLabel-root {
    color: $dark-blue;
}

.MuiListItem-root {
    color: $dark-blue;
}

.MuiFormHelperText-root {
    color: $dark-blue;
}
